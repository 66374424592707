<template>
  <ion-page>
    <ion-content>
      <p style="color:red; margin-top:80px">不正なアクセス</p>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage } from '@ionic/vue';
import { defineComponent } from 'vue';
//import axios from 'axios';

export default defineComponent({
  name: 'AccessError',
  components: {
    IonContent,
    IonPage,
  },
  data(){
      return {
      };
  },
  mounted(){
  }
});
</script>

<style scoped>
.toroku {
  margin:20px;
}
</style>