<template>
  <ion-app>
    <ion-header class="ion-text-center">
      <ion-toolbar>
        <ion-title>株式会社エスエムエス</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-router-outlet />
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet, IonHeader, IonToolbar, IonTitle } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
     IonHeader,
     IonToolbar,
     IonTitle
  },
  mounted: function() {
  }
});
</script>
<style>
@import './css/style.css';
</style>