<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <ion-card class="title">
        <ion-card-header color="secondary">
          <ion-card-title class="ion-text-center" size="large">交換・撤去</ion-card-title>
        </ion-card-header>
      <ion-card-content>
        <!-- List Inset Lines -->
        <ion-list lines="inset">
          <ion-item>
            <div class="display-block">
              <ion-label>現場名</ion-label>
              <ion-note slot="end">{{genbaName}}</ion-note>
            </div>
          </ion-item>
          <ion-item>
            <div class="display-block">
              <ion-label>住所</ion-label>
              <ion-note slot="end">{{address}}</ion-note>
            </div>
          </ion-item>
          <ion-item>
            <div class="display-block">
              <ion-label>コンテナ番号</ion-label>
              <ion-note slot="end">{{contCd}}</ion-note>
            </div>
          </ion-item>
          <ion-item>
            <div class="display-block">
              <ion-label>設置日</ion-label>
              <ion-note slot="end">{{dateFormat(startDate)}}</ion-note>
            </div>
          </ion-item>
          <ion-item>
            <div class="display-block">
              <ion-label>廃棄物量</ion-label>
              <ion-note slot="end">
                <select v-model="haiki" style="width:150px">
                  <option value="1">50%</option>
                  <option value="2">80%</option>
                  <option value="3">100%</option>
                  <option value="9">それ以外</option>
                </select>
              </ion-note>
            </div>
          </ion-item>
        </ion-list>

        <ion-button v-if="protype!=2" id="kakutei" color="primary" expand="block" @click="clickKakutei">確定</ion-button>
        <ion-button id="back" color="warning" @click="backPage">戻る</ion-button>
      </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<style scoped>
.display-block {
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>

<script>

import { IonContent, IonPage, IonCardTitle, IonLabel, IonCard, IonCardContent, IonButton, IonList, IonItem, IonNote } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Kakutei',
  components: {
    IonContent,
    IonPage,
    IonCardTitle,
    IonLabel,
    IonCard,
    IonCardContent,
    IonButton,
    IonList,
    IonItem,
    IonNote,
    //IonSelect,
    //IonSelectOption
  },
  data(){
      return {
        memberId:'',
        genbaName:'',
        address:'',
        contCd:'',
        startDate:'',
        haiki: 0
      };
  },
  ionViewDidEnter() {
    this.setContInfo();
  },
  mounted() {
    this.setContInfo();
  },
  methods: {
    setContInfo: function () {
      this.memberId = this.$route.params.memberId;
      this.genbaName = this.$route.params.genbaName;
      this.address = this.$route.params.address;
      this.startDate = this.$route.params.startDate;
      this.contCd = this.$route.params.contCd;
    },
    clickKakutei: function () {
      var self = this;

      self.axios.put(`genba-change/${self.contCd}`, { member_id: self.memberId, cont_cd: self.contCd, haiki: self.haiki }).then(res => {
        if(res.data["result"] == "success"){
          self.axios.post('mail', { mobile_type: 1, member_id: self.memberId, cont_cd: self.contCd, p_type: 2 });
          self.$router.push('/kanryo');
        }else{
          alert("error");
        }
      });
    },
    backPage: function () {
      this.$router.back();
    },
  }
});
</script>

<style scoped>
#kakutei {
  margin-top: 20px;
  margin-left:20px;
  margin-right:20px;
}
#back {
  margin:20px;
}
#title {
  width:100%;
  margin-left:auto;
  margin-right:auto;
}
#tokui-text {
  font-size:20px;
  text-decoration: underline;
  color: rgb(25, 148, 170);
}
</style>