<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <ion-card class="title">
        <ion-card-header color="secondary">
          <ion-card-title class="ion-text-center" size="large">端末登録</ion-card-title>
        </ion-card-header>
      <ion-card-content>
        <!-- List Inset Lines -->
        <div class="conte">
            <ion-label position="floating">電話番号</ion-label>
            <ion-input id="inpcont" type="tel" v-model="phoneNum"></ion-input>
            <ion-text color="danger" v-show="inpError">{{ errMsg }}</ion-text>
        </div> 
        <ion-button id="next" color="primary" expand="block" @click="clickNext">次へ</ion-button>
      </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>

import { IonContent, IonPage, IonCardTitle, IonCard, IonButton, IonInput } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Phonenum',
  components: {
    IonContent,
    IonPage,
    IonCardTitle,
    IonCard,
    IonButton,
    IonInput
  },
  data(){
      return {
        deviceCd: '',
        phoneNum:'',
        inpError: false,
        errMsg: '電話番号の形式が違います。'
      };
  },
  mounted() {
    this.deviceCd = this.$route.query.device;
    var access_date = this.get_now_ymd();
    this.axios.post('genba-login', {device_cd: this.deviceCd, access_date: access_date}).then(res => { 
       if(res.data == 'unauthrized') {
         this.$router.push("/error");
       }
      });
  },
  methods: {
    clickNext: function () {
      if(this.isTelNo(this.phoneNum)){
        this.$router.push({ name: 'Toroku', params: { deviceCd: this.deviceCd, tel: this.phoneNum }});
      }else{
        this.inpError = true;
      }
    },
    get_now_ymd: function () {
        var dt = new Date();
        var y = dt.getFullYear();
        var m = ("00" + (dt.getMonth()+1)).slice(-2);
        var d = ("00" + dt.getDate()).slice(-2);
        var result = y + "/" + m + "/" + d;
        return result;
    }
  }
});
</script>

<style scoped>
#next {
  margin:20px;
}
#back {
  margin:20px;
}
#title {
  width:100%;
  margin-left:auto;
  margin-right:auto;
}
#tokui-text {
  font-size:20px;
  text-decoration: underline;
  color: rgb(25, 148, 170);
}
.conte {
  margin: 20px;
}
#inpcont {
  border: solid 0.5px;
  border-color: #ddd;
  border-radius: 5px;
}
</style>