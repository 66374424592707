<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <ion-card class="title">
        <ion-card-header color="secondary">
          <ion-card-title class="ion-text-center" size="large">交換・撤去</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <!-- List Inset Lines -->
          <div class="content">
            <ion-item class="ion-text-center">コンテナ交換・撤去手続きを確定しました。</ion-item>
          </div>
          <ion-button id="kakutei" color="primary" expand="block" @click="clickKakutei">QRコード読取画面に戻る</ion-button>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>

import { IonContent, IonPage, IonCardTitle, IonCard, IonButton, IonItem } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Kanryo',
  components: {
    IonContent,
    IonPage,
    IonCardTitle,
    IonCard,
    IonButton,
    IonItem
  },
  props: ['title'],
  data(){
      return {
      };
  },
  mounted() {
  },
  methods: {
    clickKakutei: function () {
      this.$router.push("/change");
    },
  }
});
</script>

<style scoped>
.content {
  margin-top: 10px;
}
#kakutei {
  margin:20px;
}
#back {
  margin:20px;
}
#title {
  width:100%;
  margin-left:auto;
  margin-right:auto;
}
</style>