<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <ion-card class="title">
        <ion-card-header color="secondary">
          <ion-card-title class="ion-text-center" size="large">端末登録</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <!-- List Inset Lines -->
          <ion-item class="ion-text-center">登録完了</ion-item>
          <ion-button id="kakutei" color="primary" expand="block" @click="clickKakutei">確定</ion-button>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>

import { IonContent, IonPage, IonCardTitle, IonCard, IonButton, IonItem } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Toroku',
  components: {
    IonContent,
    IonPage,
    IonCardTitle,
    IonCard,
    IonButton,
    IonItem
  },
  data(){
      return {
        deviceCd:'',
        tel:'',
        accessDate:'',
      };
  },
  mounted() {
  },
  methods: {
    clickKakutei: function () {
      this.deviceCd = this.$route.params.deviceCd;
      this.tel = this.$route.params.tel;
      this.accessDate = this.get_now_ymd();

      this.axios.post('genba-signup', {
        'device_cd': this.deviceCd,
        'tel': this.tel,
        'access_date': this.accessDate
      }).then(() => {
        localStorage.setItem('device_cd', this.deviceCd);
        this.$router.push("/change");
      })
    },
    get_now_ymd: function () {
        var dt = new Date();
        var y = dt.getFullYear();
        var m = ("00" + (dt.getMonth()+1)).slice(-2);
        var d = ("00" + dt.getDate()).slice(-2);
        var result = y + "/" + m + "/" + d;
        return result;
      }
  }
});
</script>

<style scoped>
#kakutei {
  margin:20px;
}
#back {
  margin:20px;
}
#title {
  width:100%;
  margin-left:auto;
  margin-right:auto;
}
#tokui-text {
  font-size:20px;
  text-decoration: underline;
  color: rgb(25, 148, 170);
}
</style>