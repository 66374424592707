<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <ion-card style="margin-top:70px">
        <ion-card-content>
          <ion-item>
            端末を登録する必要があります。
          </ion-item>
          <div>
              <ion-button class="toroku" expand="block" @click="click_signup">端末登録</ion-button>
          </div>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonButton, IonCard, IonCardContent } from '@ionic/vue';
import { defineComponent } from 'vue';
//import axios from 'axios';

export default defineComponent({
  name: 'Login',
  components: {
    IonContent,
    IonPage,
    IonButton,
    IonCard,
    IonCardContent
  },
  data(){
      return {
        token: ''
      };
  },
  mounted(){
    var self = this;
    //localStorage.clear();
    //localStorage.setItem('device_cd', '1147708160a75f8f2188a');
    var device_cd = localStorage.getItem('device_cd');
    var access_date = this.get_now_ymd();
    self.axios.post('genba-login', {device_cd: device_cd, access_date: access_date}).then(res => { 
       if(res.data == 'authrized') {
         self.$router.push("/change");
       }
      });
  },
  methods:{
      click_signup: function () {
          this.$router.push("/signup");
      },
      get_now_ymd: function () {
        var dt = new Date();
        var y = dt.getFullYear();
        var m = ("00" + (dt.getMonth()+1)).slice(-2);
        var d = ("00" + dt.getDate()).slice(-2);
        var result = y + "/" + m + "/" + d;
        return result;
      }
  }
});
</script>

<style scoped>
.toroku {
  margin:20px;
}
</style>