import axios from 'axios';

var config = {
    //baseURL:'https://api.web-container.net/api/',
    baseURL:'https://sms-api.ryukyu.co.jp/api/',
    timeout: 60*1000,
    headers:{
'Content-Type': 'application/json; charset=utf-8',
'X-Requested-With': 'XMLHttpRequest' 
//'Authorization':'abc',
    }
};

const _axios = axios.create(config);
_axios.interceptors.request.use(
    config => {
        config.headers.common['Authorization']  = 'Bearer ' + localStorage.getItem("device_cd");
        console.log(config)
        return config;  
    }
)
Plugin.install = function(app) {
  app.axios = _axios
  window.axios = _axios
  app.config.globalProperties.axios = _axios
  app.config.globalProperties.$axios = _axios
}

export default Plugin