import { createRouter, createWebHistory } from '@ionic/vue-router';
import Login from '../views/Login.vue';
import Phonenum from '../views/Phonenum.vue';
import Toroku from '../views/Toroku.vue';
import Change from '../views/Change.vue';
import Kakutei from '../views/Kakutei.vue';
import Kanryo from '../views/Kanryo.vue';
import AccessError from '../views/AccessError.vue';

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Phonenum
  },
  {
    path: '/phonenum',
    name: 'Phonenum',
    component: Phonenum
  },
  {
    path: '/toroku',
    name: 'Toroku',
    component: Toroku
  },
  {
    path: '/change',
    name: 'Change',
    component: Change
  },
  {
    path: '/kakutei',
    name: 'Kakutei',
    component: Kakutei
  },
  {
    path: '/kanryo',
    name: 'Kanryo',
    component: Kanryo 
  },
  {
    path: '/error',
    name: 'AccessError',
    component: AccessError 
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
